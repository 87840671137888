<template lang="pug">
  .h-100.maxContainer.mx-auto
    .main_box
        Header.mt-0
        .mx-auto.max-width-box-form
        svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg').text-center.d-block.mx-auto.mt-5
            path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
            path(d='M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z' fill='#F4F4F4')
        h2.mt-3.TitleSection.px-4.pb-3 La videollamada ha finalizado
        .form-data.mb-3.mt-2.py-4.mx-1.px-4.mx-4
          p.font-20.mb-5.text-center.px-4 Gracias por utilizar nuestros servicios. Te esperamos en una próxima ocasión.
          p.font-18.mb-5.text-center.px-4 Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura.
        footer.ButtonFixed.position-fixed
          b-button(v-if="redirect" variant="whitecolmedica" @click="goHome()").buttonBack.blueButton Volver al menú inicial
          b-button(v-else variant="whitecolmedica" disabled).buttonBack Ya puedes cerrar esta ventana
</template>

<script>
import { mapActions, mapState } from "vuex";
import LS from "../helpers/localStorage";
import Header from "./autoservice/components/Header";
// import LogoColmedica from "@/assets/colmedica_logo.png";

export default {
  name: "Cancelled",

  data: () => ({
    naturaleza: LS.getItem("naturaleza"),
    redirect: null
    // LogoColmedica
  }),

  components: {
    Header
  },

  beforeDestroy() {
    this.clearLog();
  },

  computed: {
    ...mapState(["env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  },

  created() {
    if (this.$route.query) {
      this.redirect = this.$route.query.redirect;
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      endMeet: "meeting/endMeet",
      putLog: "log/put",
      clearLog: "log/clear",
      goToView: "autoservice/goToView"
    }),

    async finishTurnClose() {
      LS.removeItem("naturaleza");
      this.$router.push({
        name: "Profile_dev"
      });
    },

    goToSchedule() {
      this.goToView("Home");
      setTimeout(() => {
        this.$router.push("/ad/");
      }, 1000);
    },

    async startupLocal() {
      try {
        await this.cancelTurn();
        this.putLog({
          name: "Canceled. Turno cancelado."
        });
      } catch (error) {
        this.putLog({
          name: "Canceled. Error no restrictivo. No se pudo cancelar el turno.",
          checked: false,
          message: error
        });
        console.error("Error in cancelTurn in canceled page", error);
      }
      try {
        await this.endMeet();
        this.putLog({
          name: "Canceled. Videollamada cancelada."
        });
      } catch (error) {
        this.putLog({
          name:
            "Canceled. Error no restrictivo. No se pudo cancelar la videollamada.",
          checked: false,
          message: error
        });
        console.error("Error in endMeet in canceled page", error);
      }
    },

    goHome() {
      setTimeout(() => {
        this.$router.push("/oa/");
      }, 1000);
    }
  }
};
</script>
<style scoped>
.main_text {
  font-size: 1.5em;
}
.main_box {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 1em;
  color: black;
  background-color: white;
  height: fit-content;
  padding-bottom: 8rem;
}
.maxContainer {
  max-width: 411px;
  background-color: white;
}
.client_logo {
  width: 140px;
  height: auto;
}
.header {
  margin-top: 3vh;
  margin-bottom: 3vh;
}
.text-header-container {
  position: relative;
  height: 10vh;
  width: 50%;
  float: left;
}
.turn-detail {
  padding: 3vh;
  font-size: 1.5em;
  font-weight: 600;
}
.div_center_button {
  position: absolute;
  bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 90%;
}
.ButtonFixed {
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 280px;
  width: 100%;
}

.LinkUnderlined {
  color: var(--color-gray);
  text-decoration: underline;
}

.textLargeDesc {
  color: #66707e;
}

.buttonBack {
  font-weight: 500;
  font-size: 20.3521px;
  line-height: 27px;
  position: fixed;
  bottom: 31.11px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 380px;
  width: 100%;
  opacity: 1;
  background-color: #f4f4f4;
}

.font-20 {
  font-size: 18px;
  line-height: 23px;
}

.blueButton {
  background-color: #00aeee;
  color: white;
}
</style>
